import { graphql } from "gatsby"

import BlogPage from "../blog"

export const query = graphql`
  query postListQuery {
    allMarkdownRemark(
      filter: {
        frontmatter: { template: { eq: "blog-post" }, tags: { in: "defense" } }
      }
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      nodes {
        id
        fields {
          slug
        }
        frontmatter {
          path
          title
          subtitle
          richTitle {
            html
          }
          date(formatString: "MMMM DD, YYYY")
          byline
          headerImage {
            image {
              relativePath
            }
            alt
          }
        }
      }
    }
  }
`

export default BlogPage
